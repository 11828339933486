<template>
  <b-button :disabled="showLoader" variant="info"
            :style="buttonCss"
            class="btn"
            @click="initPayment()">
    {{ label || 'Save Card' }}
    <b-spinner small label="Spinning" v-if="showLoader"></b-spinner>
  </b-button>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    label: {
      type: String
    },
    buttonCss: {
      type: String
    }
  },
  data() {
    return {
      showLoader: true,
      items: [],
      addOnplanMap: {},
      currencySymbol: null,
    }
  },
  methods: {
    async getCompanyDetails() {
      try {
        if (this.countryId) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/${this.countryId}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async initPayment() {
      if (!this.customerIdentifier) {
        if (this.workflowSettings.redirect_url_not_login) {
          window.location = this.workflowSettings.redirect_url_not_login
        } else {
          console.error('Redirect url if not logged in not set')
        }
        return
      }
      this.showLoader = true
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        email: this.email,
        payment_component: 'SAVE_CARD'
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const val = data.value
          const mode = val.subscription_mode
          const onlyAskCard = val.only_ask_card
          const amount = val.amount
          switch (mode) {
            case 'DIRECT':
              if (this.workflowSettings.redirect_url_after_payment)
                window.location = this.workflowSettings.redirect_url_after_payment
              else
                console.log('Please configure redirect url')
              break
            case 'PAYMENT':
              window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/${val.stripe_secret}?company=${this.companyId}&country=${this.countryId}&only_ask_card=${onlyAskCard}&currency=${this.currencySymbol}&amount=${amount}`
              break
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.showLoader = false
      }
    },
  },
  async mounted() {
    this.showLoader = true
    const countryDetails = await this.getCompanyDetails();
    this.currencySymbol = countryDetails.currency_symbol;
    this.showLoader = false
  }
}
</script>


<style scoped>

body {
  text-align: center;
  background-color: #e2e1e0;
}

.card {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  padding: 2rem;
  width: 300px;
  background: white;
  margin: 1rem;
  color: black;
  transition: all 0.4s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.card i {
  font-size: 200%;
}

.card .card-title {
  font-size: 180%;
}

.card .card-text {
  font-size: 120%;
}

.card a {
  text-decoration: none;
  position: absolute;
  margin-bottom: 1.5rem;
}

.card .btn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}


.btn {
  border: 1px solid transparent;
  background-image: none;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.42857143;
  touch-action: manipulation;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
}

</style>
