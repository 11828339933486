<template>
  <div class="container mt-0 product-family-pricing-widget">
    <notifications group="group_plan_preview"/>

    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="showUi">

        <!-- Toggle Buttons for Monthly/Yearly Plans -->
        <div class="d-flex justify-content-center mb-3">
          <div class="btn-group" role="group">
            <button v-if="showMonthlyTab" type="button" class="btn btn-outline-secondary"
                    :class="{ active: planType === 'MONTHLY' }"
                    @click="setPlan('MONTHLY')">
              Monthly
            </button>
            <button v-if="showYearlyTab" type="button" class="btn btn-outline-secondary"
                    :class="{ active: planType === 'YEARLY' }"
                    @click="setPlan('YEARLY')">
              Yearly
            </button>
            <button v-if="showWeeklyTab" type="button" class="btn btn-outline-secondary"
                    :class="{ active: planType === 'WEEKLY' }"
                    @click="setPlan('WEEKLY')">
              Weekly
            </button>
          </div>
        </div>

        <!-- Tabs for Different Categories -->
        <ul class="nav nav-pills justify-content-center mb-4">
          <li class="nav-item" v-for="item in catalogs" :key="item.id">
            <a class="nav-link" :class="{ active: activeTab === item.id }" @click="setTab(item.id)">
              {{ item.name }}
            </a>
          </li>
        </ul>

        <!-- Pricing Cards Section -->
        <div class="row">
          <div class="col-md-4 mb-4" v-for="(plan, index) in filteredPlans" :key="index">
            <div class="card h-100 card-div" :class="getCardClass(plan)">
              <div :class="getCardSelectedClass(plan)">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title">{{ plan.name }}</h5>
                  <h2 class="card-price">{{ currencySymbol }}{{ finalTotalCalculator(plan) }}/{{ getPerLabel() }}</h2>

                  <div class="w-100 line-separator"></div>

                  <div class="pricing-feature">
                    <div class="pricing-feature-list ov-plan-features" v-html="plan.features"></div>
                  </div>

                  <button type="button" :class="getSelectButtonSelectedClass(plan)"
                          class="btn btn-primary w-100 btn-select mt-auto" @click="selectPlan(plan)">
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Selected Plans -->
        <div class="card">
          <div class="card-header">
            <h4>Plans Selected</h4>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="(plan, index) in selectedPlans" :key="index">
                {{ plan.catalog.name }} - {{ plan.name }}: {{ currencySymbol }}{{ finalTotalCalculator(plan) }}
                <button class="btn btn-sm btn-danger float-right" @click="removePlan(plan)">Remove</button>
              </li>
            </ul>
            <div class="mt-4 d-flex justify-content-end align-items-center">
              <h5 class="mr-3 mb-0">Total Plan: {{ currencySymbol }}{{ totalCost }}</h5>
              <button
                  :disabled="selectedPlans.length === 0 || showLoader"
                  class="btn btn-dark"
                  @click="payNow">
                <b-spinner small label="Spinning" v-if="showLoader"></b-spinner>
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>


<script>
import axios from "axios";

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    email: {
      type: String
    },
    status: {
      type: String
    },
    apiKey: {
      type: String
    },
    countryCode: {
      type: String
    },
    zipCode: {
      type: String
    }
  },
  data() {
    return {
      showLoader: false,
      showOverlay: false,
      showUi: false,
      planType: 'MONTHLY',
      activeTab: '',
      selectedPlans: [],
      currencySymbol: '',
      catalogs: [],
      plans: [],
      showMonthlyTab: false,
      showYearlyTab: false,
      showWeeklyTab: false,
      workflowSettings: null,
      customerDetails: {}
    };
  },
  watch: {
    planType: function () {
      this.uniqueCatalogs()
    }
  },
  computed: {
    filteredPlans() {
      let freq = null;
      if (this.planType === 'MONTHLY')
        freq = 'month'
      if (this.planType === 'YEARLY')
        freq = 'year'
      if (this.planType === 'WEEKLY')
        freq = 'week'
      const plans = this.cleanUpPlans(this.plans, freq)
      return plans.filter((plan) => {
        return plan.catalog?.tsid === this.activeTab
      });
    },
    totalCost() {
      return this.selectedPlans.reduce((acc, plan) => acc + this.finalTotalCalculator(plan), 0);
    },
  },
  methods: {
    setPlan(planType) {
      this.planType = planType;
    },
    setTab(tab) {
      this.activeTab = tab;
    },
    selectPlan(plan) {
      const existingPlanIndex = this.selectedPlans.findIndex((p) => p.tsid === plan.tsid);
      if (existingPlanIndex !== -1) {
        this.selectedPlans.splice(existingPlanIndex, 1);
      } else {
        const sameTypeIndex = this.selectedPlans.findIndex((p) => p.catalog?.tsid === plan.catalog?.tsid);
        if (sameTypeIndex !== -1) {
          this.selectedPlans.splice(sameTypeIndex, 1, plan);
        } else {
          this.selectedPlans.push(plan);
        }
      }
    },
    removePlan(plan) {
      this.selectedPlans = this.selectedPlans.filter((p) => p.name !== plan.name);
    },
    getCardClass(plan) {
      return this.selectedPlans.includes(plan) ? 'border-selected' : 'border-light';
    },
    getCardSelectedClass(plan) {
      return this.selectedPlans.includes(plan) ? 'filled-body' : 'non-filled-body';
    },
    getSelectButtonSelectedClass(plan) {
      return this.selectedPlans.includes(plan) ? 'btn-select-select' : '';
    },
    async getPlanList() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/plans?status=${this.status}&custom=false`
      const {data} = await axios.get(url, {
        headers: headers
      })
      if (data.status) {
        this.plans = data.value
      }
    },
    async getCountryDetails() {
      try {
        if (this.countryId) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/${this.countryId}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    finalTotalCalculator(plan) {
      let finalTotal = 0
      for (let i = 0; i < plan.price.length; i++) {
        const item = plan.price[i]
        const pricing_model = item.pricing_model;
        if (pricing_model === 'FIXED') {
          const frequency = item.frequency
          if (this.planType === 'MONTHLY' && frequency.month && frequency.month.length) {
            finalTotal += frequency.month[0].price
          }
          if (this.planType === 'YEARLY' && frequency.year && frequency.year.length) {
            finalTotal += frequency.year[0].price
          }
          if (this.planType === 'WEEKLY' && frequency.week && frequency.week.length) {
            finalTotal += frequency.week[0].price
          }
        }
      }

      if (finalTotal)
        finalTotal = Number(finalTotal.toFixed(2))

      return finalTotal
    },
    cleanUpPlans(plans, freq) {
      let finalPlans = []
      for (let i = 0; i < plans.length; i++) {
        const plan = plans[i]
        let price = plan.price;
        if (!price) {
          continue
        }
        let j = 0;
        price.forEach(p => {
          const month = p.frequency[freq]
          if (month) {
            plans[i].price[j].frequency[freq] = month.filter(m => m.price !== null && m.price !== "")
            j++
          }
        })

        price = plans[i].price;
        const filtered = price.filter(p => {
          const month = p.frequency[freq]
          return (month && month.length) || 0
        })
        if (filtered && filtered.length) {
          plan.price = filtered
          finalPlans.push(plan)
        }
      }
      return finalPlans
    },
    getPerLabel() {
      if (this.planType === 'MONTHLY')
        return 'month'
      if (this.planType === 'YEARLY')
        return 'year'
      if (this.planType === 'WEEKLY')
        return 'week'
      return ''
    },
    uniqueCatalogs() {
      const uniqueCatalogs = new Set();
      const catalogArray = [];
      let freq = null;
      if (this.planType === 'MONTHLY')
        freq = 'month'
      if (this.planType === 'YEARLY')
        freq = 'year'
      if (this.planType === 'WEEKLY')
        freq = 'week'
      const plans = this.cleanUpPlans(this.plans, freq)
      plans.forEach(plan => {
        if (plan.catalog !== null) {
          if (!uniqueCatalogs.has(plan.catalog.tsid)) {
            uniqueCatalogs.add(plan.catalog.tsid);
            catalogArray.push({id: plan.catalog.tsid, name: plan.catalog.name});
          }
        } else if (!uniqueCatalogs.has(null)) {
          uniqueCatalogs.add(null);
          catalogArray.push({id: undefined, name: 'Empty'});
        }
      });
      if (catalogArray.length > 0) {
        this.activeTab = catalogArray[0].id
      }
      this.catalogs = catalogArray
    },
    async getWorkflowSettings() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/settings?type=WORKFLOW_SETTINGS`, {
          headers: headers
        })
        if (data && data.value) {
          this.workflowSettings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    },
    async payNow() {
      if (!this.customerIdentifier) {
        if (this.workflowSettings?.redirect_url_not_login) {
          window.location = this.workflowSettings.redirect_url_not_login
        } else {
          this.$notify({
            group: 'group_plan_preview',
            type: 'error',
            title: 'Error',
            text: 'Redirect url is not configured'
          });
          console.error('Redirect url is not configured')
          //alert('Redirect url is not configured')
        }
        return
      }

      this.showLoader = true
      const planIds = [];
      this.selectedPlans.map(value => planIds.push(value.tsid))
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        email: this.email,
        plan_ids: planIds,
        plan_type: this.planType,
        payment_component: 'PLAN',
        country_code: this.countryCode,
        zip_code: this.zipCode,
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const val = data.value
          const mode = val.subscription_mode
          console.log(mode, val)
          switch (mode) {
            case 'DIRECT':
              if (this.workflowSettings.redirect_url_after_payment) {
                window.location = this.workflowSettings.redirect_url_after_payment
                window.location.reload(true)
              } else {
                console.log('Redirect url is not configured')
                alert('Redirect url is not configured')
              }
              break
            case 'PAYMENT':
              if (val.gateway === 'STRIPE' || !val.gateway)
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/${val.transaction_tsid}`
              if (val.gateway === 'PAYPAL')
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/p/${val.transaction_tsid}`
              if (val.gateway === 'RAZORPAY')
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/r/${val.transaction_tsid}`
              break
          }
        }
      } catch (e) {
        const data = e.response.data
        const message = data?.error?.message || 'Something went wrong. Please try again'
        this.$notify({group: 'group_plan_preview', type: 'error', title: 'Error', text: message});
      } finally {
        this.showLoader = false
      }
    },
    async getCustomerDetails() {
      try {
        if (this.customerIdentifier) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    fillSelectedPlans() {
      if (this.customerDetails?.subscriptions) {
        const subscriptions = this.customerDetails?.subscriptions
        for (let i = 0; i < subscriptions.length; i++) {
          const tsid = subscriptions[i].plan.tsid
          const matchedPlan = this.plans.filter(plan => {
            return plan.tsid === tsid
          })
          if (matchedPlan && matchedPlan.length) {
            this.selectedPlans.push(matchedPlan[0])
          }
        }
      }
    }
  },
  async mounted() {
    this.showOverlay = true
    await this.getPlanList()
    await this.getWorkflowSettings()
    const countryDetails = await this.getCountryDetails();
    this.customerDetails = await this.getCustomerDetails();
    this.fillSelectedPlans();

    this.currencySymbol = countryDetails.currency_symbol;
    this.uniqueCatalogs()
    this.showUi = true
    this.showOverlay = false

    const monthlyPlans = this.cleanUpPlans(JSON.parse(JSON.stringify(this.plans)), 'month')
    if (monthlyPlans.length > 0) {
      this.showMonthlyTab = true
    }

    const yearlyPlans = this.cleanUpPlans(JSON.parse(JSON.stringify(this.plans)), 'year')
    if (yearlyPlans.length > 0) {
      this.showYearlyTab = true
    }

    const weeklyPlans = this.cleanUpPlans(JSON.parse(JSON.stringify(this.plans)), 'week')
    if (weeklyPlans.length > 0) {
      this.showWeeklyTab = true
    }

    if (this.showMonthlyTab + this.showYearlyTab + this.showWeeklyTab === 1) {
      this.showMonthlyTab = false
      this.showYearlyTab = false
      this.showWeeklyTab = false
    }
  }
};
</script>

<style>
.product-family-pricing-widget {
  :root {
    --card-default-background: #FDDCC5;
    --card-default-border: #FFC7A0;
    --button-fill-color: #FFD3B4;
    --button-text-color: #F66E0C;
    --card-selected-color: #F66E0C;
  }

  .card-price {
    font-size: 2em;
    font-weight: bold;
  }

  .nav-pills .nav-link {
    background-color: rgba(0, 123, 255, 0.46);
    border-radius: 0rem;
  }

  .border-primary {
    border-width: 2px !important;
  }


  .pricing-feature {
    border-radius: 8px;
  }

  .pricing-feature > h4 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 21px;
  }

  .pricing-feature > h4 + .pricing-feature-list {
    margin: 24px 0 0;
  }

  .pricing-feature-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .pricing-feature-list > img {
    width: 14px;
    margin: 6px 0 0;
  }

  .pricing-feature-list > p {
    width: calc(100% - 14px);
    margin: 0;
    padding: 0 0 0 12px;
    color: #FFFFFF;
    font-size: 14px;
  }

  .pricing-feature-list + .pricing-feature-list {
    margin: 12px 0 0;
  }

  .card-div {
    background-color: #FDDCC5 !important;
    border-radius: 6px;
  }

  .pricing-feature-list > ul {
    padding-left: 16px;
  }

  .line-separator {
    border-bottom: 2px solid #FFC7A0;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  .btn-select {
    background-color: #FFD3B4 !important;
    border: 1px solid #FFD3B4 !important;
    color: #F66E0C !important;
  }

  .btn-select:focus {
    background-color: white !important;
    box-shadow: none !important;
  }

  .btn-select-select {
    background-color: white !important;
  }

  .border-selected {
    border: 2px solid #F66E0C !important;
  }

  .filled-body {
    margin: 4px;
    height: 100%;
    background: #F66E0C;
  }

  .non-filled-body {
    margin: 4px;
    height: 100%;
  }

  .nav-item {
    margin-right: 8px;
    cursor: pointer;

  }

  .nav-item:last-child {
    margin-right: 0;
  }

  .nav-pills .nav-link {
    background-color: #FFD3B4;
    color: #F66E0C !important;
  }

  .nav-pills .nav-link.active {
    color: #fff !important;
    background-color: black !important;
    border: 2px solid #F66E0C;
  }

  .card-body {
    height: 100%;
  }
}

</style>
