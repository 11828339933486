<template>
  <div class="minusplusnumber-container" :class="{'minusplusnumber-align-center': align === 'center'}">
    <label class="form-label mb-0">{{ label }}</label>
    <div class="minusplusnumber d-inline-flex align-items-center rounded mt-2">
      <button class="mpbtn btn btn-outline-secondary" v-on:click="mpminus()" :disabled="disableMinus">
        -
      </button>
      <div id="field_container" class="mx-2">
        <input type="number" v-model="newValue" class="form-control text-center border"/>
      </div>
      <button class="mpbtn btn btn-outline-secondary" v-on:click="mpplus()">
        +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    value: {
      default: 0,
      type: Number
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    label: {
      default: '',
      type: String
    },
    align: {
      type: String,
    },
    disableMinus: {
      type: Boolean,
    }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  methods: {
    mpplus: function () {
      if (this.max === undefined || (this.newValue < this.max)) {
        this.newValue = this.newValue + 1
        this.$emit('onChange', {value: this.newValue, index: this.index})
      }
    },
    mpminus: function () {
      if ((this.newValue) > this.min) {
        this.newValue = this.newValue - 1
        this.$emit('onChange', {value: this.newValue, index: this.index})
      }
    }
  },
  watch: {
    value: function (newVal) {
      this.newValue = parseInt(newVal);
    },
    newValue: function () {
      this.$emit('onChange', {value: this.newValue, index: this.index})
    }
  },
  created: function () {
    this.newValue = this.value
  }
}
</script>

<style>
.minusplusnumber-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.minusplusnumber-align-center {
  align-items: center;
}

.minusplusnumber {
  background-color: transparent;
  user-select: none;
}

#field_container input {
  width: 160px;
  font-size: 16px;
  border: none;
}

.mpbtn {
  padding: 6px 12px;
  cursor: pointer;
  background: #fff !important;
}

.mpbtn:hover {
  background-color: #f8f9fa;
}

.mpbtn:active {
  background-color: #e2e6ea;
}
</style>
