function calculateTieredPrice(qty, range) {
    let finalPrice = 0;
    for (let i = 0; i < range.length; i++) {
        const tieredPricing = range[i];
        const minRange = tieredPricing.min;
        const maxRange = tieredPricing.max;
        const price = tieredPricing.price;
        const rangeDiff = maxRange ? maxRange - minRange + (minRange !== 0 ? 1 : 0) : qty;
        if (rangeDiff <= qty) {
            finalPrice += rangeDiff * price;
        } else {
            finalPrice += qty * price;
        }
        qty = qty - rangeDiff;
        if (qty <= 0) {
            break;
        }
    }
    console.log("calculateTieredPrice", finalPrice)
    return finalPrice;
}

function calculateVolumnPrice(qty, range) {
    for (let i = 0; i < range.length; i++) {
        const volumePricing = range[i];
        const minRange = volumePricing.min;
        const maxRange = volumePricing.max;
        const price = volumePricing.price;
        if (qty >= minRange && (maxRange == null || qty <= maxRange)) {
            console.log("calculateVolumnPrice", price)
            return price * qty;
        }
    }
    return 0;
}

function calculateStairCasePrice(qty, range) {
    for (let i = 0; i < range.length; i++) {
        const stairPricing = range[i];
        const minRange = stairPricing.min;
        const maxRange = stairPricing.max;
        const price = stairPricing.price;
        if (qty >= minRange && (maxRange == null || qty <= maxRange)) {
            console.log("calculateStairCasePrice", price)
            return price;
        }
    }
    return 0;
}

module.exports = {
    calculateTieredPrice, calculateVolumnPrice, calculateStairCasePrice
}