<template>
  <div class="modal-xl-1">
    <div class="container-mod" :class="{'block': block, 'none': none}" id="a"></div>
    <div class="modal-modal" id="b" :class="{'block': block, 'none': none}">
      <div class="header-modal">
        <b-link @click="closeModal()" class="cancel">X</b-link>
      </div>
      <div class="content-modal">
        <BillingHistoryTable :company-id="companyId" :country-id="countryId"
                             :token="token" :api-key="apiKey" :currency-symbol="currencySymbol"
                             :customer-identifier="customerIdentifier"/>
      </div>
    </div>
  </div>
</template>

<style>

.modal-xl-1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}

.cancel {
  font-size: 1.0rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  text-decoration: none;
}

.cancel:hover {
  text-decoration: none;
  color: #000;
}

.container-mod {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  opacity: 0.9;
  background: #222;
  z-index: 40000;
  top: 0;
  left: 0;
  overflow: hidden;
  animation-name: fadeIn_Container;
  animation-duration: 1s;
}

.modal-modal-old {
  display: none;
  top: 0;
  margin: 0 auto;
  position: fixed;
  z-index: 40001;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #000;
  margin-top: 30px;
  margin-left: 0%;
  animation-name: fadeIn_Modal;
  animation-duration: 0.8s;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 95vh;
  padding: 30px;
}

.modal-modal {
  display: none;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  position: fixed;
  z-index: 40001;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #000;
  margin-top: 30px;
  -webkit-animation-name: fadeIn_Modal;
  animation-name: fadeIn_Modal;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 93vh;
  padding: 30px;
  width: 80%;
}

.header-modal {
  width: 100%;
  height: 70px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #ccc;
}

.header-modal a {
  text-decoration: none;
  float: right;
  line-height: 70px;
  margin-right: 20px;
  color: #aaa;
}

form {
  margin-top: 20px;
}

form label {
  display: block;
  margin-left: 12%;
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 1rem;
}

form input {
  display: block;
  width: 75%;
  margin-left: 12%;
  margin-top: 10px;
  border-radius: 3px;
  font-family: sans-serif;
}


/****MEDIA QUERIES****/

@media screen and (min-width: 600px) {
  .header-modal a {
    line-height: 40px;
    margin-right: 10px;
  }

  form label {
    margin-left: 10%;
    margin-top: 10px;
  }

  form input {
    width: 75%;
    margin-left: 10%;
    margin-top: 10px;
  }

  .footer-modal button {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }

}


@keyframes fadeIn_Modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn_Container {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}
</style>

<script>
import BillingHistoryTable from "@/components/MyAccount/BillingHistoryTable.vue";

export default {
  components: {BillingHistoryTable},
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    currencySymbol: {
      type: String
    }
  },
  data() {
    return {
      block: false,
      none: false
    }
  },
  methods: {
    openModal() {
      console.log('open')
      this.block = true;
      this.none = false
    },
    closeModal() {
      this.block = false;
      this.none = true
    }
  },
  mounted() {
  }
}
</script>
