<template>
  <div id="app">
    <Header/>
    <Home :company-id="companyId" :country-id="countryId" :country-i-s-o2="countryISO2" :status="status"
          :token="token" :api-key="apiKey"
          :customer-identifier="customerIdentifier" :email="email" :form-data="formData"
          :background-color="backgroundColor"
          :widget-type="widgetType"
          :call-from="callFrom"
          :label="label"
          :switch-background-color="switchBackgroundColor"
          :text-color="textColor"
          :workflow-settings="workflowSettings"
          :mode="mode"
          :country-code="countryCode"
          :zip-code="zipCode"
          :plan-tsid="planTsid"
          :frequency="frequency"
          :button-css="buttonCss"/>
    <Footer/>
  </div>
</template>


<script>

import Header from './Header'
import Footer from './Footer'
import Home from '../../view/Home.vue'

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    countryISO2: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    email: {
      type: String
    },
    status: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    switchBackgroundColor: {
      type: String
    },
    textColor: {
      type: String
    },
    formData: {
      type: String
    },
    widgetType: {
      type: String
    },
    label: {
      type: String
    },
    callFrom: {
      type: String
    },
    workflowSettings: {
      type: String
    },
    mode: {
      type: String
    },
    countryCode: {
      type: String
    },
    zipCode: {
      type: String
    },
    planTsid: {
      type: String
    },
    frequency: {
      type: String
    },
    buttonCss: {
      type: String
    }
  },
  components: {
    Header,
    Footer,
    Home
  }
}

</script>
